import photo1 from "../../../assets/images/appImages/member-1.png";
import photo2 from "../../../assets/images/appImages/member-2.png";
import photo3 from "../../../assets/images/appImages/member-3.png";

export const TeamData = [
  {
    id: 1,
    photo: photo1,
    name: "Doudou NDIAYE",
    role: "Founder / Business manager",
  },
  {
    id: 2,
    photo: photo2,
    name: "Modibo DIAWARA",
    role: "Manager boutique",
  },
  {
    id: 3,
    photo: photo3,
    name: "Mdeye Coumba NDIAYE",
    role: "Commercial manager",
  },
];
