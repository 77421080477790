import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import { TeamData } from "./data";
import { Link } from "react-router-dom";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">AKWABA SHOP</h3>
                <div className="custom-content">
                  Akwabashop by 2GBTI  est une entreprise panafricaine creer par Doudou NDIAYE
                  qui est un Consultant/ Entrepreneur/ Investisseur. <br />
                  Nous sommes partisan du Made in Africa. 80 % de nos produits sont fait en Afrique
                  et 20 % reste du monde.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes-nous" />
            <h3 className="custom-title">Notre équipe</h3>
            <div className="row pt-4">
              {!!TeamData &&
                TeamData.map((member, key) => {
                  return (
                    <div className="col-md-3 mb-4 card-wrapper" key={key}>
                      <TeamCard
                        photo={member.photo}
                        name={member.name}
                        role={member.role}
                        key={member.id}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div style={{ padding: '5rem 0' }}></div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
